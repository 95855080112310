<template>
  <el-button-group class="group-time-filter-button">
    <el-button
      v-for="option in options"
      :key="option.value"
      class="date-range-btn"
      :class="{ active: syncModel === option.value}"
      @click="changeSelect(option.value)"
    >
      {{ option.label }}
    </el-button>
  </el-button-group>
</template>
<script>
export default {
  name: 'GroupOptionsButton',
  props: {
    model: {
      type: [String, Boolean],
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    syncModel: {
      get () { return this.model },
      set (val) { return this.$emit('update:model', val) },
    },
  },
  methods: {
    async changeSelect (type) {
      this.syncModel = type
      this.$emit('change')
    },
  },
}
</script>

<style lang="postcss" scoped>
.group-time-filter-button {
  @apply max-w-[566px];
}
.active {
  @apply border border-primary-100 bg-primary-100 text-white;
}

::v-deep .el-button {
  @apply shadow-none;
}

::v-deep .el-button:hover {
  @apply bg-primary-100 border-primary-100 text-white;
}

.date-range-btn {
  @apply px-[28px];
}
</style>
