<template>
  <section v-loading="loading" class="section-block">
    <SectionTitle title="銷售模組消費回饋設定" @edit="dialog.edit = true" />
    <el-form label-position="left" label-width="250px">
      <el-form-item label="是否啟用">
        <div class="secret-text">
          <span>{{ configData.enabled ? '啟用' : '關閉' }}</span>
        </div>
      </el-form-item>
      <el-form-item label="銷售產品回饋比率">
        <div class="secret-text">
          <span>{{ Number.isNaN(rebateRatio) ? '尚未設定' : `${rebateRatio}%` }}</span>
        </div>
      </el-form-item>
      <el-form-item label="無法獲得回饋的銷售商品設定">
        <div class="secret-text">
          <span>{{ ignoreRebateProducts }}</span>
        </div>
      </el-form-item>
    </el-form>

    <EditSalesRebateSettings v-if="dialog.edit" :data="configData" @close="dialog.edit = false" @refresh="refresh" />
  </section>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from 'vue'
import EditSalesRebateSettings from './EditSalesRebateSettings.vue'
import { FindShopRebateSalesCashback } from '@/api/rebate'
import store from '@/store'
import { map } from 'lodash'

const shopId = computed(() => store.getters.shop)
const dialog = reactive({
  edit: false,
})
const configData = ref({})
const loading = ref(false)
const rebateRatio = computed(() => {
  return configData.value.addRate * 100
})
const ignoreRebateProducts = computed(() => {
  const products = map(configData.value.IgnoreAddSalesProducts, 'name')
  if (!products) return '尚未設定'
  if (!products.length) return '尚未設定'
  return products.join('、')
})

const findShopRebateSalesCashback = async () => {
  const [res, err] = await FindShopRebateSalesCashback({ shopId: shopId.value })
  if (err) window.$message.error(err)
  configData.value = res
}

const refresh = async () => {
  loading.value = true
  console.log({ shopId: shopId.value })
  await findShopRebateSalesCashback()
  loading.value = false
}

onMounted(async () => {
  await refresh()
})
</script>

<style scoped lang="postcss">

</style>
