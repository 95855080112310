<template>
  <el-dialog
    title="回饋金期限設定"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form ref="formRef" :model="formData" :rules="formRules">
      <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
        <p class="font-bold"><span class="text-danger">* </span>有效期限</p>
        <el-tooltip placement="right">
          <div slot="content">
            當您更改回饋金有效期限時，不<br>
            會影響顧客已擁有的回饋金日 <br>
            期，僅會影響未來發生的操作或 <br>
            交易
          </div>
          <span class="material-icons">help_outline</span>
        </el-tooltip>
      </div>
      <el-form-item prop="expTime">
        <el-select
          v-model="formData.expTime"
          name="type"
        >
          <el-option
            v-for="option in options"
            :key="option.value"
            :label="`${option.value} 天`"
            :value="option.value"
          />
        </el-select>
      </el-form-item>
      <div class="flex items-center" style="gap: 8px; margin-bottom: 8px">
        <p class="font-bold"><span class="text-danger">* </span>到期通知發送天數</p>
        <el-tooltip placement="right">
          <div slot="content">
            系統將於您在到期日前 X 天的中<br>
            午 12 點推播 Line 訊息提醒<br>
            舉例：若設定到期日前 3 天通<br>
            知假設某位客人的回饋金是<br>
            12/31 到期，系統將於 12/28<br>
            的中午 12 點提醒
          </div>
          <span class="material-icons">help_outline</span>
        </el-tooltip>
      </div>
      <el-form-item prop="dayNotify">
        <el-input v-model="formData.dayNotify" clearable />
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button
        ref="createBtn"
        v-loading="loading"
        type="primary"
        @click="updateShopRebateYearExpiration"
      >
        確認
      </el-button>
    </div>
  </el-dialog>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from 'vue'
import { UpdateShopRebateYearExpiration } from '@/api/rebate'
import { noEmptyRules, isDigitRules } from '@/validation'
import formUtils from '@/utils/form'
import store from '@/store'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
})
const emit = defineEmits(['close', 'refresh'])

const shopId = computed(() => store.getters.shop)

const loading = ref(false)
const formRef = ref(null)
const formData = reactive({
  expTime: 30,
  dayNotify: 0,
})
const formRules = {
  // expTime: [noEmptyRules()],
  dayNotify: [noEmptyRules(), isDigitRules()],
}
const options = [
  { value: 30 },
  { value: 60 },
  { value: 90 },
  { value: 180 },
  { value: 360 },
]

const updateShopRebateYearExpiration = async () => {
  if (loading.value) return
  loading.value = true
  if (!(await formUtils.checkForm(formRef.value))) {
    loading.value = false
    return
  }
  const [, err] = await UpdateShopRebateYearExpiration({
    shopId: shopId.value,
    daysUntilExpiration: formData.expTime,
    daysDiffExpirationNotify: formData.dayNotify,
  })
  loading.value = false
  if (err) return (window.$message.error(err))
  window.$message.success('更新成功！')
  emit('refresh')
  emit('close')
}

const syncData = async () => {
  formData.expTime = props.data.daysUntilExpiration
  formData.dayNotify = props.data.daysDiffExpirationNotify
}

onMounted(() => {
  syncData()
})
</script>

<style scoped lang="postcss">

</style>
